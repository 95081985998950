import React from "react";
import { Link } from "gatsby";

import { makeStyles } from '@mui/styles';
import {theme} from '../components/theme/theme';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';

import InfoIcon from '@mui/icons-material/Info';
import ShareIcon from '@mui/icons-material/Share';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/source-sans-pro";

import {offers} from '../components/indexes/offers';
import {informations} from '../components/indexes/informations';
import Bg1 from "../images/background/abst-formLarge.svg"
import BorderBgBottom from "../images/background/wave-haikei-bottom.svg"
import BorderBgTop from "../images/background/wave-haikei.svg"
import {isMobile} from 'react-device-detect';
import loadable from '@loadable/component';

const Layout = loadable(() => import('../components/common/Layout'));
const Seo = loadable(() => import("../components/seo"));
const ServicesList = loadable(() => import("../components/ServicesList"));
const Methodology = loadable(() => import("../components/Methodology"));


const useStyles = makeStyles({
  root: {
    background: theme.palette.text.hint,
  },
  bg:{
    backgroundImage: `url(${Bg1})`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'left center',
  },
  borderBottom:{
    backgroundImage: `url(${BorderBgBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center bottom',
    border: 0,
  },
  borderTop:{
    backgroundImage: `url(${BorderBgTop})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    border: 0,
  },
  promotions: {
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignItems: 'space-between',
    paddingBottom: '20vh',
    paddingTop: '10vh',
  },
  promotion: {
    width: '100%',
    maxWidth: 1720,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    alignItems: 'stretch',
    padding: 0,
    marginTop: 50,
    marginBottom: 50,
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '35vh',
    background: 'transparent',
  },
  card: {
    minWidth: '280px',
    maxWidth: '680px',
    padding: '55px 45px 20px',
    flex: '1 1 50%',
    display: 'flex',
    alignContent: 'stretch',
    alignItems: 'stretch',
    background: theme.palette.common.white,
    borderRadius: 10,
    },
    mobileCard: {
      minWidth: '280px',
      padding: 35,
      paddingTop: 30,
      paddingBottom: 5,
      flex: '1 1 68%',
      display: 'flex',
      alignContent: 'stretch',
      alignItems: 'stretch',
      background: theme.palette.common.white,
      borderRadius: 10,
    },
    CardContent:{
      minWidth: 280,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      flex: '1 1 50%',
      padding: 0
    },
    CardActions:{
      padding: 0,
      paddingTop: 40,
    },
    media: {
      minHeight: 400,
      minWidth: '400px',
      flex: '1 1 40%',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      alignContent: 'center',
      justifyContent: 'center',
      background: 'transparent',
    },
    mobileMedia: {
      minHeight: 250,
      minWidth: '340px',
      flex: '1 1 30%',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      background: 'transparent',
    },
  image: {
    flex: '1 0 auto',
    minHeight: 400,
    background: 'transparent',
    '&:nth-child(1)':{
      backgroundColor: 'transparent',
      minHeight: 400,
    },
  },
  img: {
      backgroundColor: 'transparent',
      minHeight: 400,

    },
    titleWrap: {
      padding: '380px 160px 20px',
    },
    mobilTitleWrap: {
      padding: '120px 40px 20px',
    },
    title: {
      maxWidth: 600,
    },
    subtitle: {
      maxWidth: 450,
      color: theme.palette.text.secondary
    },
  characteristic: {
    color: theme.palette.primary.dark
  },
  value: {
    color: theme.palette.primary.main
  }
})

const Loquehacemos = () => {
  const classes = useStyles();
  const cardClass = (isMobile) ? 'mobileCard' : 'card';
  const cardMediaClass = (isMobile) ? 'mobileMedia' : 'media';
  const linea = informations.filter((o) => (o.id === 9))[0];
  const titleWrap = (isMobile) ? 'mobilTitleWrap' : 'titleWrap';
  const Meta = [
  ];
  return (
    <Layout>
      <Seo title="Lo que hacemos" meta={Meta}/>
      <ServicesList />
      <Methodology />
      <Box className={classes.root}>
      <Box className={classes.bg}>
      <Box className={classes.borderTop}>
      <Box className={classes.borderBottom}>
        <Container  className={classes[titleWrap]} maxWidth='xl'  >
          <Typography className={classes.title}  gutterBottom variant="h4" component="h2" color="textPrimary" >{linea.title}</Typography>
          <Typography className={classes.subtitle} gutterBottom variant="h5" color="textSecondary" component="h3">{linea.text}</Typography>
        </Container>
        <Container maxWidth='xl' className={classes.promotions}>
          {offers.map((offer,i) => (
            <Paper  elevation={0} className={classes.promotion} key={i} id={offer.anchor}>
            <div className={classes[cardMediaClass]} ><div className={classes.image}><Image className={classes.img} color='transparent' contain aspectRatio={(16/9)} src={offer.image} /></div></div>
            <Card elevation={0}  className={classes[cardClass]} >

              <CardContent className={classes.CardContent}>
                <div>
                <Typography gutterBottom variant="h6" component="h4" color='secondary'>
                  {offer.cat}
                </Typography>
                <Typography gutterBottom variant="h4" component="h3"  color='primary'>
                  {offer.title}
                </Typography>
                <Typography paragraph={true}  variant="subtitle1" component="p">
                  {offer.excerpt}
                </Typography><br />
                </div>
                <div>
                  <div className={classes.value}>
                  <Typography gutterBottom variant="h6" component="p">
                    {offer.price}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {offer.valid}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {offer.requirements}
                  </Typography>
                  </div>
                </div>
                <CardActions className={classes.CardActions}>
                <Link to={'/ofertas/' + offer.anchor} onClick={void(0)} >
                  <Button color="secondary" variant="contained" startIcon={<InfoIcon />} > Más Info </Button>
                </Link>
                  <IconButton color="secondary" disableFocusRipple={true} medium variant="outlined">
                    <ShareIcon />
                  </IconButton>
                  </CardActions>
              </CardContent>
            </Card>
            </Paper>
            ))}
            </Container>
        </Box>
        </Box>
        </Box>

      </Box>
    </Layout>
)};
export default Loquehacemos;
